type HttpMethod = "GET" | "POST" | "PUT" | "DELETE";

const fetchHelper = (url: string, method: HttpMethod, data?: unknown) => {
    return fetch(url, {
        method: method || "GET",
        body: data ? JSON.stringify(data) : undefined,
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then(async (response) => {
            if (!response.ok) {
                let errorMessage = response.statusText;
                try {
                    const resJson = await response.json();
                    if (resJson) errorMessage = resJson.errorMessage;
                    // eslint-disable-next-line no-empty
                } catch { }
                throw new Error(errorMessage);
            }

            return response;
        });
};

export const fetchText = (url: string, method: HttpMethod, data?: unknown) => {
    return fetchHelper(url, method, data)
        .then((response) => {
            return response.text();
        });
};

export const fetchJson = (url: string, method: HttpMethod, data?: unknown) => {
    return fetchHelper(url, method, data)
        .then(async (response) => {
            try {
                const resJson = await response.json();
                return resJson ?? {};
            } catch {
                return {};
            }
        });
};
