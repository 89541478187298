import React, { FC, useContext, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { fetchJson } from "../helpers/fetchHelper";
import validate from "validate.js";
import InvalidFormInput from "../components/InvalidFormInput";
import Layout from "../components/layout/Layout";
import Breadcrumb from "../components/layout/Breadcrumb";
import Program from "../components/home/Program";
import Map from "../components/home/Map";
import { AppContext } from "../context/AppContext";
import { Helmet } from "react-helmet";

const title = "Contact";

type FormDataType = {
    name: string;
    email: string;
    message: string;
    consent: boolean;
};

const Contact: FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isMessageSent, setIsMessageSent] = useState(false);
    const [formData, setFormData] = useState<FormDataType>({
        name: "",
        email: "",
        message: "",
        consent: false,
    });
    const { addToast } = useContext(AppContext);

    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    config {
                        apiUrl
                        recaptchaSiteKey
                    }
                }
            }
        }
    `);

    const handleSetState = (key: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        setFormData(prevState => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleSetConsent = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.checked;
        setFormData(prevState => ({
            ...prevState,
            consent: value,
        }));
    };

    const onSubmitContact = () => {
        // @ts-ignore
        grecaptcha.ready(() => {
            setIsLoading(true);

            // @ts-ignore
            grecaptcha.execute(data.site.siteMetadata.config.recaptchaSiteKey, { action: "submit" })
                .then((token: string) => {
                    return fetchJson(`${data.site.siteMetadata.config.apiUrl}contact`, "POST", {
                        grt: token,
                        name: formData.name,
                        email: formData.email,
                        message: formData.message,
                    });
                })
                .then(() => {
                    setIsMessageSent(true);
                    setIsLoading(false);
                })
                .catch((err: Error) => {
                    addToast("danger", err.message);
                    setIsLoading(false);
                });
        });
    };

    const formValidation: validate.ValidateJS = validate({ ...formData }, {
        name: {
            presence: { allowEmpty: false },
            length: { minimum: 6, maximum: 100 },
        },
        email: {
            presence: { allowEmpty: false },
            email: true,
        },
        message: {
            presence: { allowEmpty: false },
            length: { minimum: 6, maximum: 255 },
        },
        consent: {
            presence: { allowEmpty: false },
            inclusion: {
                within: [true],
            },
        },
    });

    return (
        <Layout isLoading={isLoading}>
            <Helmet title={title} />
            <Breadcrumb title={title} />

            <Program animationVisible={true} />

            <section className="form-area section-padding-100-70">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="section-heading">
                                <p>Ai o întrebare?</p>
                                <h3>Suntem aici pentru tine</h3>
                            </div>

                            {!isMessageSent && (
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="name" className="required">Numele tău</label>
                                            <input
                                                type="text" id="name" placeholder="Nume..." value={formData.name}
                                                onChange={handleSetState("name")} className="form-control" required
                                            />
                                            <InvalidFormInput formValidation={formValidation} fieldName="name" fieldValue={formData.name} />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="email" className="required">Adresa ta de email</label>
                                            <input
                                                type="email" id="email" placeholder="Email..." value={formData.email}
                                                onChange={handleSetState("email")} className="form-control" required
                                            />
                                            <InvalidFormInput formValidation={formValidation} fieldName="email" fieldValue={formData.email} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="message" className="required">Mesajul tău</label>
                                            <textarea
                                                id="message" placeholder="Mesaj..." value={formData.message}
                                                onChange={handleSetState("message")} cols={30} rows={10} className="form-control" required
                                            >
                                            </textarea>
                                            <InvalidFormInput formValidation={formValidation} fieldName="message" fieldValue={formData.message} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group form-check text-center">
                                            <input
                                                type="checkbox" id="consent" checked={formData.consent} onChange={handleSetConsent} className="form-check-input"
                                            />
                                            <label htmlFor="consent" className="form-check-label">Sunt de acord ca datele mele cu caracter personal să fie prelucrate strict în vederea comunicării unui răspuns la acest mesaj.</label>
                                        </div>
                                    </div>
                                    <div className="col-12 text-center">
                                        <button
                                            type="submit" className="btn btn-primary" onClick={onSubmitContact}
                                            disabled={isLoading || formValidation != null}
                                        >
                                            Trimite
                                        </button>
                                    </div>
                                    <div className="col-12 text-center text-muted small mt-4">
                                        This site is protected by reCAPTCHA and the Google&nbsp;
                                        <a href="https://policies.google.com/privacy" title="Google reCAPTCHA Privacy Policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and&nbsp;
                                        <a href="https://policies.google.com/terms" title="Google reCAPTCHA Terms of Service" target="_blank" rel="noopener noreferrer">Terms of Service</a> apply.
                                    </div>
                                </div>
                            )}

                            {isMessageSent && (
                                <div className="alert alert-success">Am primit mesajul tău. Vom răspunde în cel mai scurt timp posibil!</div>
                            )}
                        </div>
                    </div>
                </div>
            </section>

            <Map />
        </Layout>
    );
};

export default Contact;
